import {formatSwapTokenList, getLocalRPC} from './methods'
import {tokenListUrl, VERSION, USE_VERSION} from '../constant'

export const ETH_MAIN_CHAINID = 1
export const ETH_MAINNET = getLocalRPC(ETH_MAIN_CHAINID, 'https://mainnet.infura.io/v3/fdd4494101ed4a28b41bb66d7fe9c692')
export const ETH_MAIN_EXPLORER = 'https://etherscan.io'

export const tokenList = [
  {
    "address": "",
    "chainId": ETH_MAIN_CHAINID,
    "decimals": 6,
    "name": "Tether USD",
    "symbol": "USDT"
  }
]

const symbol = 'ETH'

const bridgeToken = {
  [VERSION.V1]: {
    bridgeInitToken: '',
    bridgeInitChain: '56',
    nativeToken: '',
    crossBridgeInitToken: 'ETH'
  },
}

export default {
  [ETH_MAIN_CHAINID]: {
    wrappedToken: '',
    tokenListUrl: tokenListUrl + ETH_MAIN_CHAINID,
    tokenList: formatSwapTokenList(symbol, tokenList),
    ...bridgeToken[USE_VERSION],
    swapInitToken: '',
    multicall: '',
    nodeRpc: ETH_MAINNET,
    nodeRpcList: [
      ETH_MAINNET
    ],
    chainID: ETH_MAIN_CHAINID,
    lookHash: ETH_MAIN_EXPLORER + '/tx/',
    lookAddr: ETH_MAIN_EXPLORER + '/address/',
    lookBlock: ETH_MAIN_EXPLORER + '/block/',
    explorer: ETH_MAIN_EXPLORER,
    symbol: symbol,
    name: 'Ethereum',
    networkName: 'Ethereum mainnet',
    type: 'main',
    label: ETH_MAIN_CHAINID,
    isSwitch: 1,
    suffix: 'ERC20',
  }
}
