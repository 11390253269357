import React, {useContext} from "react"
import { useTranslation } from 'react-i18next'
import { CheckCircle } from 'react-feather'
import { ButtonConfirmed, ButtonLight } from '../Button'
import TokenLogo from "../TokenLogo"
import Loader from '../Loader'
import {useActiveWeb3React} from '../../hooks'
import styled, { ThemeContext } from "styled-components"
import config from '../../config'
import {selectNetwork} from '../../config/tools/methods'

import {useNonApproveCallback} from '../../hooks/useApproveCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import {useAllApproved} from './hooks'
import AppBody from "../../pages/AppBody"

const ApproveBox = styled.div`
  ${({ theme }) => theme.flexSC};
  flex-wrap:wrap;
  width: 100%;
  max-width: 600px;
  margin:auto;
`
const ApproveListView = styled.div`
  width: 50%;
  padding: 15px;
`
const ApproveListCont = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.contentBg};
  padding: 25px;
  border-radius: 15px;
`

const ApproveLoadingCont = styled.div`
  ${({ theme }) => theme.flexC};
  width: 100%;
  background: ${({ theme }) => theme.contentBg};
  padding: 25px;
  border-radius: 15px;
  min-height: 300px;
  font-weight:bold;
`

const TokenLogoBox = styled.div`
  width: 100%;
  margin-bottom: 20px;
  ${({ theme }) => theme.flexC};
`

const ButtonConfirmedBox = styled(ButtonConfirmed)`
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  // margin-top: 20px;
`

const ButtonOverBox = styled.div`
${({ theme }) => theme.flexC};
  height: 30px;
  font-size: 14px;
`
const PageTip = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.textColor};
  padding: 0 0 20px;
  font-size: 20px;
  font-weight: bold;
`

const PageInfo = styled.div`
color: ${({ theme }) => theme.textColor};
padding: 0 0 20px;
font-size: 18px;
text-align:center;
font-size:14px;
font-weight:normal;
`

function ApproveBtn ({
  token,
  spender,
  symbol,
  disabled,
  curChainId
}: {
  token:any,
  spender:any,
  symbol:any,
  disabled:any,
  curChainId: any
}) {
  const {chainId} = useActiveWeb3React()
  const { t } = useTranslation()
  const {approve} = useNonApproveCallback(chainId?.toString() === curChainId?.toString() && token ? token : undefined, spender ?? undefined, symbol)
  
  const theme = useContext(ThemeContext)
  function changeNetwork (chainID:any) {
    selectNetwork(chainID).then((res: any) => {
      // console.log(res)
      if (res.msg === 'Error') {
        alert(t('changeMetamaskNetwork', {label: config.getCurChainInfo(chainID).networkName}))
      }
    })
  }
  if (disabled) {
    return <ButtonOverBox>
    <CheckCircle size="16" stroke={theme.green1} style={{marginRight: '10px'}} />Updated
  </ButtonOverBox>
  } else if (chainId?.toString() !== curChainId?.toString()) {
    return <ButtonConfirmedBox disabled={disabled} onClick={() => {
      changeNetwork(curChainId)
    }}>
      {t('SwitchTo') + config.getCurChainInfo(curChainId).name}
  </ButtonConfirmedBox>
  }
  return (
    <ButtonConfirmedBox disabled={disabled} onClick={() => {
      approve()
    }}>
      Revoke
    </ButtonConfirmedBox>
  )

}

export default function NonApproveQuery () {
  const {approvedList, loading} = useAllApproved()
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const toggleWalletModal = useWalletModalToggle()

  if (!account) {
    return (<>
      <AppBody>
        <ApproveLoadingCont>
          <ButtonLight onClick={toggleWalletModal}>{t('ConnectWallet')}</ButtonLight>
        </ApproveLoadingCont>
      </AppBody>
    </>)
  }
  return (
    <AppBody>
      <PageInfo>
      {/* Multichain network is under the the process of upgrading and migrating Router contracts to support native coins(e.g., Luna) and non-EVM blockchains(e.g., Solana). Some of the bridges of wrapped native coins(e.g., WETH,WBNB) will be suspend temporarily. */}
      </PageInfo>
      {approvedList.length > 0 ? <PageTip>Router contract updated, please revoke your token approvals from old router contract first.</PageTip> : ''}
      <ApproveBox>
        {
          approvedList.length > 0 ? approvedList.map((item:any, index:any) => {
            return (
              <ApproveListView key={index}>
                <ApproveListCont>
                  <TokenLogoBox><TokenLogo symbol={config.getCurChainInfo(item.chainId)?.networkLogo ?? config.getCurChainInfo(item.chainId)?.symbol} size={'36px'} style={{marginRight:'10px'}}></TokenLogo></TokenLogoBox>
                  <ApproveBtn
                    token={item.token}
                    spender={item.spender}
                    symbol={item.symbol}
                    disabled={!item.isAllowance}
                    curChainId={item.chainId}
                  />
                </ApproveListCont>
              </ApproveListView>
            )
          }) : (<ApproveLoadingCont>
            {
              loading ? <Loader stroke="#5f6bfb" style={{width: '38px',height: '38px'}} /> : 'You haven’t approved any obsolete contract. No actions needed.'
            }
          </ApproveLoadingCont>)
        }
      </ApproveBox>
    </AppBody>
  )
}