import React from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useAppState } from '../../state/application/hooks'
import config from '../../config'
import initPath from '../../assets/question.svg'

const Image = styled.img<{ size?: any }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  max-width: 100%;
  max-height: 100%;
  background-color: white;
  border-radius: ${({ size }) => size};
`
function getSourcePath(symbol: any) {
  let path = ''
  try {
    path = require('../../assets/coin/source/' + symbol + '.svg')
  } catch (error) {
    try {
      path = require('../../assets/coin/source/' + symbol + '.png')
    } catch (error) {
      path = initPath
    }
  }
  return path
}

export default function TokenLogo({
  symbol,
  size = '1rem',
  style,
  logoUrl,
  ...rest
}: {
  symbol?: any
  size?: any
  style?: React.CSSProperties
  logoUrl?: any
}) {
  const { chainId } = useActiveWeb3React()
  const { tokenIcons } = useAppState()
  let path = ''
  symbol = config.getBaseCoin(symbol, chainId)
  symbol = symbol === 'W' + config.getCurChainInfo(chainId).symbol ? symbol.substr(1) : symbol
  if (symbol && tokenIcons[symbol.toUpperCase()]) {
    path = tokenIcons[symbol.toUpperCase()]
  } else {
    if (logoUrl) {
      path = logoUrl
    } else if (symbol) {
        path = getSourcePath(symbol)
        }
  }
  return <Image {...rest} alt={symbol} src={path} size={size} style={style} />
}