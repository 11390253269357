
import { useSelector } from 'react-redux'
import { AppState } from '../index'

export function usePoolsState(): any {
  const poolLiquidity:any = useSelector<AppState, AppState['pools']>(state => state.pools.poolLiquidity)
  if (!poolLiquidity) return {}

  return poolLiquidity
}


