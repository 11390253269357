import { formatSwapTokenList, getLocalRPC } from './methods'
import { tokenListUrl, VERSION, USE_VERSION } from '../constant'

export const BNB_MAIN_CHAINID = 97
export const BNB_MAINNET = getLocalRPC(BNB_MAIN_CHAINID, "https://data-seed-prebsc-1-s1.binance.org:8545")
export const BNB_MAIN_EXPLORER = 'https://testnet.bscscan.com'

export const tokenList = [
  {
    address: '0xB1cFd152e565F8D9E20C299b5625c2c57ceF2eF6',
    chainId: BNB_MAIN_CHAINID,
    decimals: 18,
    name: 'My Token',
    symbol: 'WBNB'
  }
]

const symbol = 'BNB'

const bridgeToken = {
  [VERSION.V1]: {
    bridgeInitToken: '',
    bridgeInitChain: '1',
    nativeToken: '',
    crossBridgeInitToken: 'BNB'
  }
}

export default {
  [BNB_MAIN_CHAINID]: {
    wrappedToken: '0x54d41cAc0e34924D9Cd1bb0B7f9cD1fF28Be50A0',
    storage: '0x052c675CDB6568Db0870f9e361a11c21C169E33f',
    tokenListUrl: tokenListUrl,
    tokenList: formatSwapTokenList(symbol, tokenList, "https://cryptologos.cc/logos/bnb-bnb-logo.png?v=032"),
    ...bridgeToken[USE_VERSION],
    swapInitToken: '',
    multicall: '0x8E7E773855Ca0c79B2976B44aa67Fd179b4b74A6',
    nodeRpc: BNB_MAINNET,
    nodeRpcList: [
      'https://data-seed-prebsc-1-s1.binance.org:8545',
    ],
    chainID: BNB_MAIN_CHAINID,
    lookHash: BNB_MAIN_EXPLORER + '/tx/',
    lookAddr: BNB_MAIN_EXPLORER + '/address/',
    lookBlock: BNB_MAIN_EXPLORER + '/block/',
    explorer: BNB_MAIN_EXPLORER,
    symbol: symbol,
    name: 'BSC',
    networkName: 'BSC Testnet',
    type: 'main',
    label: BNB_MAIN_CHAINID,
    isSwitch: 1,
    suffix: 'BEP20',
  },
}
