import { formatSwapTokenList, getLocalRPC } from './methods'
import { tokenListUrl, VERSION, USE_VERSION } from '../constant'

export const MOCHAIN_MAIN_CHAINID = 22936
export const MOCHAIN_MAINNET = getLocalRPC(MOCHAIN_MAIN_CHAINID, 'https://testnet-rpc.mochain.app/')
export const MOCHAIN_MAIN_EXPLORER = 'https://motestnet.app'
export const tokenList = [
  {
    address: '0x32dCFf3b5194c8f74FDc38bbF543d51Bd38B601C',
    chainId: MOCHAIN_MAIN_CHAINID,
    decimals: 18,
    name: 'My Token',
    symbol: 'MTK'
  },
]

const symbol = 'MO'

const bridgeToken = {
  [VERSION.V1]: {
    bridgeInitToken: '',
    bridgeInitChain: '56',
    nativeToken: '',
    crossBridgeInitToken: ''
  }
}

export default {
  [MOCHAIN_MAIN_CHAINID]: {
    wrappedToken: '0x54d41cAc0e34924D9Cd1bb0B7f9cD1fF28Be50A0',
    storage: '',
    tokenListUrl: tokenListUrl,
    tokenList: formatSwapTokenList(symbol, tokenList),
    ...bridgeToken[USE_VERSION],
    swapInitToken: '',
    multicall: '0x052c675CDB6568Db0870f9e361a11c21C169E33f',
    timelock: '',
    nodeRpc: MOCHAIN_MAINNET,
    nodeRpcList: [
        MOCHAIN_MAINNET,
      'https://testnet-rpc.mochain.app/',
    ],
    chainID: MOCHAIN_MAIN_CHAINID,
    lookHash: MOCHAIN_MAIN_EXPLORER + '/tx/',
    lookAddr: MOCHAIN_MAIN_EXPLORER + '/address/',
    lookBlock: MOCHAIN_MAIN_EXPLORER + '/block/',
    explorer: MOCHAIN_MAIN_EXPLORER,
    symbol: symbol,
    name: 'MO',
    networkName: 'MO Testnet',
    type: 'main',
    label: MOCHAIN_MAIN_CHAINID,
    isSwitch: 1,
    suffix: 'MO',
  }
}
