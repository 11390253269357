type Item = {
  token: string
  multiswapToken: string
  symbol: string
  spender: string
}

export const nonApproveList: {
  [chainId: number]: Item[]
} = {}
