import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import config from '../../config'
import { useAppState } from '../../state/application/hooks'

const HeaderLinks = styled.div`
  display: flex;
  width: 100%;
  padding: 1.5625rem 1.5625rem 0.625rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    ${({ theme }) => theme.flexBC}
    padding: 0.5rem 1rem;
  `};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexSC}
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  font-weight: 500;
  color: ${({ theme }) => theme.textNav};
  box-sizing: border-box;
  padding: 1.1rem 0.875rem;
  line-height: 1rem;
  margin: 6px 0;
  height: 48px;
  position: relative;
  white-space: nowrap;
  font-size: 1.3rem;

  .icon {
    ${({ theme }) => theme.flexC};
    width: 38px;
    height: 38px;
    border-radius: 100%;
    background: ${({ theme }) => theme.navIconBg};
    margin-right: 1rem;
    .on {
      display: none;
    }
    .off {
      display: block;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.textColor};
    .icon {
      background: ${({ theme }) => theme.navBg2};
      .on {
        display: block;
      }
      .off {
        display: none;
      }
    }
  }
  &.${activeClassName} {
    border-bottom: 2px solid ${({ theme }) => theme.primary3};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: center;
    border-radius: .6rem;

    &.${activeClassName} {
      color: #ffffff;
      background: ${({ theme }) => theme.bgColorLinear};
      border-bottom: none;
      font-weight: 600;
      .icon {
        background: ${({ theme }) => theme.navBg};
        .on {
          display: block;
        }
        .off {
          display: none;
        }
      }
    }

    ${({ theme }) => theme.flexC};
    margin:0;
    .icon {
      display:none;
    }
  `}
`

export default function NavList() {
  const { t } = useTranslation()
  const { menuLinks } = useAppState()

  return (
    <>
      <style>
        {`
          .menuLink {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-align-items: left;
            -webkit-box-align: left;
            -ms-flex-align: left;
            align-items: left;
            outline: none;
            cursor: pointer;
            -webkit-text-decoration: none;
            text-decoration: none;
            width: 100%;
            font-weight: 500;
            color: #cdd1dd;
            box-sizing: border-box;
            padding: 1.1rem 0.875rem;
            line-height: 1rem;
            margin: 6px 0;
            height: 48px;
            position: relative;
            white-space: nowrap;
            font-size: 1.3rem;
          }
          .menuLink:hover {
            color: #979dac;
          }
        `}
      </style>
      <HeaderLinks>
        {config.getCurConfigInfo().isOpenRouter ? (
          <StyledNavLink
            id={`swap-nav-link`}
            to={config.getCurConfigInfo().isOpenBridge ? '/router' : '/swap'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/router') ||
              pathname.startsWith('/swap') ||
              (config.getCurConfigInfo().isOpenBridge &&
                (pathname.startsWith('/pool') ||
                  pathname.startsWith('/add') ||
                  pathname.startsWith('/remove') ||
                  pathname.startsWith('/create') ||
                  pathname.startsWith('/find')))
            }
          >
            <div className="icon">
              <img src={require('../../assets/icon/router.svg')} className="off" alt="" />
              <img src={require('../../assets/icon/network-white.svg')} className="on" alt="" />
            </div>
            {config.getCurConfigInfo().isOpenBridge ? t('router') : t('swap')}
          </StyledNavLink>
        ) : (
          ''
        )}
        {config.getCurConfigInfo().isOpenSwap ? (
          <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            <div className="icon">
              <img src={require('../../assets/icon/swap.svg')} className="off" alt="" />
              <img src={require('../../assets/icon/swap-purpl.svg')} className="on" alt="" />
            </div>
            {t('swap')}
          </StyledNavLink>
        ) : (
          ''
        )}
        {config.getCurConfigInfo().isOpenMerge ? (
          <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            {t('router')}
          </StyledNavLink>
        ) : (
          ''
        )}
        <StyledNavLink id={`pool-nav-link`} to={'/pool'}>
          {t('pool')}
        </StyledNavLink>
        {Array.isArray(menuLinks) && menuLinks.length && (
          <>
            {menuLinks.map((item, key) => {
              return (
                <a className="menuLink" href={item.address} key={key}>{item.title}</a>
              )
            })}
          </>
        )}
      </HeaderLinks>
    </>
  )
}
