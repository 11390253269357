export const tokenListUrl = 'https://test.multiswap.org/bsc.json'

export enum VERSION {
  V1 = 'STABLE',
}

export enum BRIDGE_KEY {
  routerTokenList = 'routerTokenList',
  bridgeTokenList = 'bridgeTokenList',
  mergeTokenList = 'mergeTokenList'
}

export const CROSS_BRIDGE_LIST = [BRIDGE_KEY.bridgeTokenList]
export const env: any = 'pro'
export const version = '0.1.0'
export const timeout = 1000 * 60 * 30
export const INIT_VERSION = VERSION.V1

function getUrlVersion(init: any) {
  return init
}
export const USE_VERSION: any = getUrlVersion(INIT_VERSION)
function initVersion(version: any, configVersion: any) {
  const VERSION = version + '_VERSION'
  const curVersion = localStorage.getItem(VERSION)
  if (curVersion && curVersion !== configVersion) {
    sessionStorage.clear()
    localStorage.clear()
    localStorage.setItem(VERSION, configVersion)
  } else if (!curVersion) {
    localStorage.setItem(VERSION, configVersion)
  }
}
initVersion(USE_VERSION, version)

function formatHiddenCoin(list?: Array<any>) {
  const arr: any = []
  if (list && list.length > 0) {
    for (let str of list) {
      arr.push(str)
      if (str.indexOf('any') !== 0) str = 'any' + str
      arr.push(str)
    }
  }
  return arr
}
export const controlConfig: any = {
  [VERSION.V1]: {
    hiddenCoin: formatHiddenCoin([]),
    hiddenChain: [],
    showCoin: [],
    showChain: [],
    initNode: '1',
    isOpenSwap: 1,
    isOpenRouter: 0,
    isOpenBridge: 0,
  },
}

export const ENV_NODE_CONFIG = USE_VERSION + '_ENV_NODE_CONFIG'

export const INIT_NODE = controlConfig[USE_VERSION].initNode

export const BASECURRENCY = 'BASECURRENCY'
