import React, { KeyboardEvent, useState, RefObject, useCallback, useEffect, useRef, useMemo } from 'react'
import { Currency, Token } from '../../sdk'
import { Text } from 'rebass'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useTranslation } from 'react-i18next'
import Column from '../Column'
import { RowBetween } from '../Row'
import Modal from '../Modal'
import QuestionHelper from '../QuestionHelper'
import { PaddedColumn, SearchInput, Separator } from '../SearchModal/styleds'
import { filterTokens } from '../SearchModal/filtering'
import { useTokenComparator } from '../SearchModal/sorting'
import { CloseIcon } from '../../theme'
import { isAddress } from '../../utils'
import { useLocalToken } from '../../hooks/Tokens'
import CurrencyList from './CurrencyList'

interface CurrencySearchModalProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  allTokens?: any
  chainId?: any
  bridgeKey?: any
  allBalances?: any
  showETH?: any
}

export default function SearchModal ({
  isOpen,
  onDismiss,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  allTokens = {},
  chainId,
  bridgeKey,
  showETH
}: CurrencySearchModalProps) {
  const { t } = useTranslation()
  const {tokenComparator, balances: allBalances} = useTokenComparator(bridgeKey, chainId, false)

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [intervalCount, setIntervalCount] = useState<any>(0)

  const inputRef = useRef<HTMLInputElement>()

  const isAddressSearch = isAddress(searchQuery)
  const useAllTokenList = useMemo(() => {
    const list:any = {}
    for (const token in allTokens) {
      const obj:any = allTokens[token].tokenInfo ? allTokens[token].tokenInfo : allTokens[token]
      list[token] = {
        ...obj
      }
    }
    return list
  }, [allTokens])
  const searchToken = useLocalToken(searchQuery && useAllTokenList[searchQuery?.toLowerCase()] ? useAllTokenList[searchQuery?.toLowerCase()] : '')

  const tokenList = useMemo(() => {
    const arr:any = []
    for (const token in useAllTokenList) {
      arr.push(useAllTokenList[token])
    }
    return arr
  }, [useAllTokenList])

  const filteredTokens: Token[] = useMemo(() => {
    if (isAddressSearch) return searchToken ? [searchToken] : []
    return filterTokens(Object.values(tokenList), searchQuery)
  }, [isAddressSearch, searchToken, tokenList, searchQuery])

  const filteredSortedTokens: Token[] = useMemo(() => {
    if (searchToken) return [searchToken]
    const sorted = filteredTokens.sort(tokenComparator)
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter(s => s.length > 0)
    if (symbolMatch.length > 1) return sorted
    const arr = [
      ...(searchToken ? [searchToken] : []),
      ...sorted.filter(token => token.symbol?.toLowerCase() === symbolMatch[0]),
      ...sorted.filter(token => token.symbol?.toLowerCase() !== symbolMatch[0])
    ]
    setTimeout(() => {
      setIntervalCount(1)
    }, 500)
    if (arr.length > 50 && intervalCount === 0) {
      return arr.splice(0, 50)
    } else {
      return arr
    }
  }, [searchQuery, searchToken, tokenComparator, filteredTokens, intervalCount])

  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen])

  useEffect(() => {
    return () => {
      // Clean up any subscriptions or async tasks here
    };
  }, []); // Empty dependency array means this effect runs only once when the component mounts

  const handleInput = useCallback(event => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
  }, [])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (onCurrencySelect) {
        onCurrencySelect(currency)
        onDismiss()
      }
    },
    [onCurrencySelect]
  )
  
  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        if (filteredSortedTokens.length > 0) {
          console.log("aa")
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() === searchQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0])
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, searchQuery]
  )

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={80} minHeight={80}>
      <Column style={{ width: '100%', flex: '1 1' }}>
        <PaddedColumn gap="14px">
          <RowBetween>
            <Text fontWeight={500} fontSize={16}>
              {t('selectToken')}
              <QuestionHelper text={t('tip6')} />
            </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <SearchInput
            type="text"
            id="token-search-input"
            placeholder={t('tokenSearchPlaceholder')}
            value={searchQuery}
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={handleInput}
            onKeyDown={handleEnter}
          />
        </PaddedColumn>
        <Separator />
        <div style={{ flex: '1' }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <>
                <CurrencyList
                  height={height}
                  showETH={showETH}
                  currencies={filteredSortedTokens}
                  onCurrencySelect={handleCurrencySelect}
                  otherCurrency={otherSelectedCurrency}
                  selectedCurrency={selectedCurrency}
                  allBalances={allBalances}
                  bridgeKey={bridgeKey}
                />
              </>
            )}
          </AutoSizer>
        </div>
      </Column>
    </Modal>
  )
}