const bitcoin = require('bitcoinjs-lib')
const OPS = require('bitcoin-ops')

const NETWORK = bitcoin.networks.bitcoin

function getNetwork (coin?:string) {
  let network = NETWORK
  coin = coin ? coin.toUpperCase() : ''
  if (coin.indexOf('BTC') !== -1) {
    network = NETWORK
  } 
  return network
}

export function createAddress (address:string, coin:string | undefined, initAddr:string) {
  const network = getNetwork(coin)
  address = address.replace('0x', '')
  const {hash} = bitcoin.address.fromBase58Check(initAddr)

  const reddemScript = bitcoin.script.compile([
    Buffer.from(address, 'hex'),
    OPS.OP_DROP,
    OPS.OP_DUP,
    OPS.OP_HASH160,
    Buffer.from(hash,'hex'),
    OPS.OP_EQUALVERIFY,
    OPS.OP_CHECKSIG,
  ])
  const output = bitcoin.script.compile([
    OPS.OP_HASH160,
    bitcoin.crypto.hash160(reddemScript),
    OPS.OP_EQUAL,
  ])
  const p2shAddress = bitcoin.payments.p2sh({
    output: output,
    network: network,
  })
  return p2shAddress.address;
}

export function isSpecAddress (address:string, coin?:string) {
  const network = getNetwork(coin)
  try {
    bitcoin.address.toOutputScript(address, network)
    return address
  } catch (error) {
    return false
  }
}