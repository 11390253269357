// @ts-nocheck
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useActiveWeb3React } from '../../hooks'
import { AppSettingsData, updateAppOptions, updateAppSettings, updateRouterData } from '../../state/application/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useAppState } from '../../state/application/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { chainInfo } from '../../config/chainConfig'
import { updateStorageData } from '../../utils/storage'
import { useMainConfigContract } from '../../hooks/useContract'
import { API_REGEXP, EVM_ADDRESS_REGEXP } from '../../constants'
import { ButtonPrimary, ButtonOutlined, CleanButton } from '../../components/Button'
import Accordion from '../../components/Accordion'
import DeployMulticonfig from './DeployMulticonfig'
import DeployRouter from './DeployRouter'
import FetchMainConfig from './FetchMainConfig'
import { Notice } from './index'
import { selectNetwork } from '../../config/tools/methods'
import config from '../../config'
import TokenSettings from './TokenSettings'
import OptionLabel from './OptionLabel'

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.3rem 0;
`

export const Select = styled.select`
  width: 100%;
  padding: 0.4rem 0;
  margin: 0.2rem 0;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.text3};
  outline: none;
  font-size: inherit;
  background-color: transparent;
  color: inherit;
  &.hasError {
    background-color: #e5c7c7;
    border-bottom: 1px solid #9f0808;
    padding-left: 10px;
    color: #c50a0a;
  }
`

export const SelectOption = styled.option`
  width: 100%;
  padding: 0.4rem 0;
  margin: 0.2rem 0;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.text3};
  outline: none;
  font-size: inherit;
  background-color: rgb(21,26,47);
  color: inherit;
`

export const Input = styled.input`
  width: 100%;
  padding: 0.4rem 0;
  margin: 0.2rem 0;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.text3};
  outline: none;
  font-size: inherit;
  background-color: transparent;
  color: inherit;
  &.hasError {
    background-color: #e5c7c7;
    border-bottom: 1px solid #9f0808;
    padding-left: 10px;
    color: #c50a0a;
  }
  &.hasError::placeholder {
    color: #c50a0a;
  }
`

export const Title = styled.h2<{ noMargin?: boolean }>`
  margin: ${({ noMargin }) => (!noMargin ? '1.6rem 0 0.8rem' : '0')};

  :first-child {
    margin-top: 1rem;
  }
`

const SubTitle = styled.h4<{ margin?: string }>`
  margin: ${({ margin }) => margin || '0 0 0.5rem'};
`

const ConfigInfo = styled.div`
  font-weight: 500;
`

const ConfigLink = styled.a`
  word-break: break-all;
`

const Textarea = styled.textarea`
  resize: vertical;
  padding: 0.3rem;
  margin: 0 0 0.3rem;
  font-size: inherit;
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => theme.text3};
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg2};
`

const CopyButton = styled(CleanButton)`
  border: 1px solid ${({ theme }) => theme.text2};
  transition: 0.1s;

  :hover {
    opacity: 0.6;
    background-color: ${({ theme }) => theme.bg2};
  }
`

const updateAppSetupSettings = (appSettings: any, setAppSettings: any, dispatch: any, newAppSettings: any) => {
  setAppSettings({
    ...appSettings,
    ...newAppSettings
  })

  dispatch(
    updateAppSettings({
      appSettings: {
        ...appSettings,
        ...newAppSettings
      }
    })
  )

}

export default function Contracts() {
  const { chainId, account, library } = useActiveWeb3React()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    owner,
    apiAddress: stateApiAddress,
    routerAddress: stateRouterAddress,
    serverAdminAddress: stateServerAdminAddress,
    appSettings: stateAppSettings,
    appSettings: {
      mainConfigAddress: stateMainConfigAddress,
      mainConfigChainId: stateMainConfigChainId,
    }
  } = useAppState()
  const addTransaction = useTransactionAdder()

  const [appSettings, setAppSettings] = useState( stateAppSettings )

  const { STORAGE_CHAIN_ID, getCurChainInfo } = config

  const [mainConfigNetworkName, setMainConfigNetworkName] = useState(
    stateMainConfigChainId ? chainInfo[stateMainConfigChainId]?.networkName : ''
  )

  const componentMounted = useRef(true)

  useEffect(() => {
    return () => {
      componentMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (stateMainConfigChainId) {
      setMainConfigNetworkName(chainInfo[stateMainConfigChainId]?.networkName || '')
    }
  }, [stateMainConfigChainId])

  const [mainConfigChainId, setMainConfigChainId] = useState<string>(`${appSettings.mainConfigChainId}` || '')
  const [mainConfigAddress, setMainConfigAddress] = useState(appSettings.mainConfigAddress)
  const [multiswapConfigOwner, setMulticonfigOwner] = useState('')

  const multiswapConfig = useMainConfigContract(mainConfigAddress, Number(mainConfigChainId) || 0)
  const multiswapConfigSigner = useMainConfigContract(mainConfigAddress, Number(mainConfigChainId) || 0, true)

  const [onStorageNetwork, setOnStorageNetwork] = useState(false)

  useEffect(() => {
    setOnStorageNetwork(chainId === config.STORAGE_CHAIN_ID)
  }, [chainId])

  const [onConfigNetwork, setOnConfigNetwork] = useState(false)

  useEffect(() => {
    setOnConfigNetwork(Boolean(stateMainConfigChainId && chainId === Number(stateMainConfigChainId)))
  }, [chainId, stateMainConfigChainId])

  const [apiAddress, setApiAddress] = useState(stateApiAddress)
  const [apiIsValid, setApiIsValid] = useState(false)

  useEffect(() => {
    setApiIsValid(apiAddress === '' || Boolean(apiAddress && apiAddress.match(API_REGEXP)))
  }, [apiAddress])

  const saveApiAddress = () => {
    if (!account) return

    try {
      updateStorageData({
        library,
        owner: account,
        data: {
          apiAddress
        },
        onReceipt: (receipt, success) => {
          if (success) {
            dispatch(updateAppOptions([{ key: 'apiAddress', value: apiAddress }]))
          }
        },
        onHash: (hash) => {
          addTransaction(
            { hash },
            {
              summary: `Server API address successfully saved`
            }
          )
        }
      })
    } catch (error) {
      console.error('API address: ', error)
    }
  }

  const [mpcAddress, setMpcAddress] = useState(stateServerAdminAddress || '')
  const [validMpcOptions, setValidMpcOptions] = useState(false)

  useEffect(() => {
    setValidMpcOptions(Boolean(mpcAddress?.match(EVM_ADDRESS_REGEXP)))
  }, [mpcAddress])

  const saveServerAdminAddress = async () => {
    if (!account) return

    if (validMpcOptions) {
      await updateStorageData({
        library,
        owner: account,
        data: {
          serverAdminAddress: mpcAddress
        },
        onReceipt: (receipt, success) => {
          if (success) {
            dispatch(updateAppOptions([{ key: 'serverAdminAddress', value: mpcAddress }]))
          }
        },
        onHash: (hash) => {
          addTransaction(
            { hash },
            {
              summary: `Validator node address saved`
            }
          )
        }
      })
    }
  }



  useEffect(() => {
    if (!mainConfigAddress || !mainConfigChainId || !multiswapConfig) {
      return setMulticonfigOwner('')
    }

    const fetch = async () => {
      if (multiswapConfig !== null) {
        const owner = '0x5341384Ba4E11B82b0529F1c028C43c4063C8bC0'; 
        componentMounted.current && setMulticonfigOwner(owner)
      }
    }

    fetch()
  }, [mainConfigAddress, mainConfigChainId, multiswapConfig])

  const saveMainConfig = useCallback(() => {
    if (!account) return
    const newAppSettings: AppSettingsData = {
      ...stateAppSettings,
      mainConfigAddress,
      mainConfigChainId: mainConfigChainId && parseInt(mainConfigChainId) || undefined,
    }

    return updateStorageData({
      library,
      owner: account,
      data: { appSettings: newAppSettings },
      onReceipt: (receipt, success) => {
        if (success) {
          dispatch(
            updateAppOptions([
              { key: 'appSettings', value: newAppSettings}
            ])
          )
        }
      },
      onHash: (hash) => {
        addTransaction(
          { hash },
          {
            summary: `Swap router ${mainConfigAddress} saved to node config`
          }
        )
      }
    })
  }, [mainConfigAddress, mainConfigChainId, account])

  const [routerChainId, setRouterChainId] = useState('')
  const [routerAddress, setRouterAddress] = useState('')
  const [routerConfirmCount, setRouterConfirmCount] = useState(3)

  useEffect(() => {
    if (chainId !== undefined && stateRouterAddress[chainId]) {
      setRouterChainId(String(chainId))
      setRouterAddress(stateRouterAddress[chainId])
      if (stateAppSettings?.multiswapConfigs[`${chainId}:${stateRouterAddress[chainId]}`]) {
        setRouterConfirmCount(stateAppSettings.multiswapConfigs[`${chainId}:${stateRouterAddress[chainId]}`].confirmations)
      }
    } else {
      setRouterChainId('')
      setRouterAddress('')
    }
  }, [chainId, stateRouterAddress[chainId || 0]])

  const [displayRouterSettings, setDisplayRouterSettings] = useState(!!stateRouterAddress[chainId || 0])
  const [savedDeployedRouterAddress, setSavedDeployedRouterAddress] = useState(stateRouterAddress[chainId || 0])

  useEffect(() => {
    setDisplayRouterSettings(!!stateRouterAddress[chainId || 0])
    setSavedDeployedRouterAddress(stateRouterAddress[chainId || 0])
  }, [chainId, stateRouterAddress])

  const serverAdminAddressBalance = useETHBalances(stateServerAdminAddress ? [stateServerAdminAddress] : [])?.[stateServerAdminAddress ?? '']?.toSignificant(6)

  const showRouterSettings = () => setDisplayRouterSettings(true)

  const [canSaveChainConfig, setCanSaveChainConfig] = useState(false)

  useEffect(() => {
    setCanSaveChainConfig(Boolean(onConfigNetwork && routerChainId && routerAddress))
  }, [onConfigNetwork, routerChainId, routerAddress])

  const setChainConfig = async () => {
    if (!multiswapConfigSigner) return

    try {
      const { name } = chainInfo[routerChainId]

      const tx = await multiswapConfigSigner.setChainConfig(routerChainId, {
        BlockChain: name,
        RouterContract: routerAddress,
        Confirmations: routerConfirmCount,
        InitialHeight: 0
      })

      const receipt = await tx.wait()

      if (receipt.status) {
        dispatch(updateRouterData({ chainId: Number(routerChainId), routerAddress: routerAddress, routerConfirmCount: routerConfirmCount }))
        addTransaction(
          { hash: receipt.transactionHash },
          {
            summary: `onChain swap router ${routerAddress} saved to node config`
          }
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  const updateUnderlyingToken = useCallback(({
    networkId,
    address,
    decimals,
    symbol,
    name,
  } : {
      networkId: string
      address: string
      decimals: any
      symbol: any
      name: any
  }) => {
      updateAppSetupSettings(
          appSettings,
          setAppSettings,
          dispatch,
          {
            erc20Tokens: {
              ...appSettings.erc20Tokens,
              [`${networkId}:${address}`]: {
                chainId: networkId,
                address: address,
                decimals,
                symbol,
                name,
                icon: ''
              }
            }
          }
        )
  }, [appSettings, setAppSettings, dispatch])

  const [canSaveMulticonfig, setCanSaveMulticonfig] = useState(false)

  useEffect(() => {
    setCanSaveMulticonfig(
      Boolean(
        onStorageNetwork &&
          mainConfigChainId &&
          mainConfigAddress &&
          multiswapConfigOwner &&
          multiswapConfigOwner.toLowerCase() === owner.toLowerCase()
      )
    )
  }, [onStorageNetwork, mainConfigChainId, mainConfigAddress, multiswapConfigOwner])

  const onDeployMulticonfig = (contractAddress: string, chainId: number) => {
    setMainConfigChainId(`${chainId}`)
    setMainConfigAddress(contractAddress)
    updateAppSetupSettings(
      appSettings,
      setAppSettings,
      dispatch,
      {
        mainConfigChainId: chainId,
        mainConfigAddress: contractAddress
      }
    )
  }

  const onDeployRouter = (contractAddress: string, chainId: number) => {
    setRouterChainId(`${chainId}`)
    setRouterAddress(contractAddress)
    updateAppSetupSettings(
      appSettings,
      setAppSettings,
      dispatch,
      {
        multiswapConfigs: {
          ...appSettings.multiswapConfigs,
          [`${chainId}:${contractAddress}`]: {
            chainId,
            address: contractAddress
          }
        }
      }
    )
  }
  const onDeployCrosschainToken = (contractAddress: string, chainId: number, underlying: any ) => {
    updateAppSetupSettings(
      appSettings,
      setAppSettings,
      dispatch,
      {
        crosschainTokens: {
          ...appSettings.crosschainTokens,
          [`${chainId}:${contractAddress}`]: {
            chainId,
            contractAddress,
            underlying,
          }
        }
      }
    )
  }
  const setRouterData = (selectedContract: any) => {
    if (selectedContract !== `-`) {
      const {
        chainId,
        address,
        confirmations
      } = appSettings.multiswapConfigs[selectedContract]
      setRouterChainId(`${chainId}`)
      setRouterAddress(address)
      setRouterConfirmCount(confirmations)
    } else {
      setRouterChainId(``)
      setRouterAddress(``)
      setRouterConfirmCount(3)
    }
  }

  const [commandToStartServer, setCommandToStartServer] = useState('')

  useEffect(() => {
    const command = `/root/setup ${stateMainConfigChainId} ${stateMainConfigAddress} <YOUR PRIVATE KEY>`

    setCommandToStartServer(command)
  }, [stateMainConfigAddress, stateMainConfigChainId])

  const copyServerCommand = () => {
    window.navigator.clipboard.writeText(commandToStartServer)
  }

  const changeNetwork = (chainID: any) => {
    selectNetwork(chainID).then((res: any) => {
      console.log(res)
      if (res.msg === 'Error') {
        alert(t('changeMetamaskNetwork', { label: getCurChainInfo(chainID).networkName }))
      }
    })
  }

  const SwitchToChainButton = ({ chainId }: { chainId: any }) => {
    const networkName = chainInfo[chainId]?.networkName
    return (
      <ButtonPrimary onClick={() => changeNetwork(chainId)}>
        {t('Switch to network', { network: networkName })}
      </ButtonPrimary>
    )
  }

  const nativeCoinSybmol = chainInfo[chainId || 0].symbol
  const lookAddress = chainInfo[chainId || 0].lookAddr

  const onFetchMainConfigCallback = (newAppSettings: any) => {
    updateAppSetupSettings(
      appSettings,
      setAppSettings,
      dispatch,
      {
        ...newAppSettings
      }
    )
  }

  return (
    <>
      <Title noMargin>{t('Main config contract')}</Title>
      <Notice margin="0.5rem 0 0">
        {stateMainConfigChainId && stateMainConfigAddress ? (
          <ConfigInfo>
            <SubTitle>{t('configInformation')}</SubTitle>
            {mainConfigNetworkName}:{' '}
            <ConfigLink
              href={`${chainInfo[stateMainConfigChainId]?.lookAddr}${stateMainConfigAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              {stateMainConfigAddress}
            </ConfigLink>
            <FetchMainConfig onFetchCallback={onFetchMainConfigCallback} />
          </ConfigInfo>
        ) : (
          <>
            {t('You need to deploy and save the config first')}. {t('You can deploy the config on any network')}. {t('You need only one config')}
            .
          </>
        )}
      </Notice>

      {!stateMainConfigAddress ? (
        <Accordion title={t('Deploy and save config')} margin="0.5rem 0">
          <DeployMulticonfig onDeploymentCallback={onDeployMulticonfig} />
          <OptionWrapper>
            <Notice warning margin="0.4rem 0 0.6rem">
              {t('After deployment, fill these settings.')}.{' '}
            </Notice>
            <OptionLabel displayChainsLink>
              {t('Config ChainID')}
              <Input
                type="number"
                min="1"
                placeholder=""
                value={mainConfigChainId || chainId}
                onChange={event => setMainConfigChainId(event.target.value)}
              />
              {t('Config contract address')}
              <Input
                type="text"
                placeholder="0x..."
                value={mainConfigAddress}
                onChange={event => setMainConfigAddress(event.target.value)}
              />
            </OptionLabel>
          </OptionWrapper>
          {onStorageNetwork ? (
            <ButtonPrimary disabled={!canSaveMulticonfig} onClick={saveMainConfig}>
              {t('Save config')}
            </ButtonPrimary>
          ) : (
            <SwitchToChainButton chainId={STORAGE_CHAIN_ID} />
          )}
        </Accordion>
      ) : (
        <>
          <Title>{t('Validator Node Settings')}</Title>
          <div>
            5. Save &quot;https://api.your-domain.com&quot; as &quot;Validator Node Address&quot; to the below field:
          </div>
          <OptionWrapper>
            <strong>{t('validator Node Address')}</strong>
            <Input type="text" value={apiAddress} onChange={event => setApiAddress(event.target.value)} />
            {onStorageNetwork ? (
              <ButtonPrimary onClick={saveApiAddress} disabled={!apiIsValid}>
                {t('Save address')}
              </ButtonPrimary>
            ) : (
              <SwitchToChainButton chainId={STORAGE_CHAIN_ID} />
            )}
          </OptionWrapper>
          <div>
            6. Create a new address in Metamask (or you can use an existing private key at your own risk) and export its
            private key (it will be used as &lt; YOUR PRIVATE KEY &gt; in 7 step) and save this new address as
            &quot;Validator Node Address&quot;.
          </div>
          <OptionWrapper>
            <Input
              type="text"
              placeholder="0x..."
              value={mpcAddress}
              onChange={event => setMpcAddress(event.target.value)}
            />
            {onStorageNetwork ? (
              <ButtonPrimary onClick={saveServerAdminAddress} disabled={!validMpcOptions}>
                {t('Save Admin Address')}
              </ButtonPrimary>
            ) : (
              <SwitchToChainButton chainId={STORAGE_CHAIN_ID} />
            )}
          </OptionWrapper>

          {stateMainConfigAddress && stateServerAdminAddress && (
            <>
              <SubTitle margin="1.4rem 0 0.5rem">7. {t('Start the validator server with this command:')}</SubTitle>
              <OptionWrapper>
                <Textarea
                  value={commandToStartServer}
                  onChange={event => setCommandToStartServer(event.target.value)}
                />
                <CopyButton onClick={copyServerCommand}>{t('Copy to clipboard')}</CopyButton>
              </OptionWrapper>

              <div>8. Add at least 0.12 coins in every network you plan to use (BSC, Polygon, etc)</div>
            </>
          )}
        </>
      )}

      {(savedDeployedRouterAddress || stateServerAdminAddress) && (
        <>
          <Title>{t('Network info')}</Title>
          <Notice margin="0.5rem 0 0">
            <ConfigInfo>
              {savedDeployedRouterAddress && (
                <>
                <SubTitle>{t('Saved Router Address')}: </SubTitle>
                <a
                  href={`${lookAddress}${savedDeployedRouterAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {savedDeployedRouterAddress}
                </a>
                </>
              )}
              {stateServerAdminAddress && (
                <>
                  <SubTitle margin='0.5rem 0.5rem 0.5rem 0'>{t('Validator Node Network Address')}: </SubTitle>
                  <a
                    href={`${lookAddress}${stateServerAdminAddress}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {stateServerAdminAddress}
                  </a>
                  <SubTitle margin='0.5rem 0.5rem 0.5rem 0'>{t('Validator Address Balance')}: </SubTitle>
                  {serverAdminAddressBalance === undefined ? `${t('Loading')}...` : `${serverAdminAddressBalance} ${nativeCoinSybmol}`}
                  {serverAdminAddressBalance !== undefined && (parseFloat(serverAdminAddressBalance) < 0.12) && (
                    <Notice warning margin="0.4rem 0 0.6rem">
                      {t('Add funds to the validator wallet!', { symbol: nativeCoinSybmol })}
                    </Notice>
                  )}
                </>
              )}
            </ConfigInfo>
          </Notice>
        </>
      )}

      <Title>{t('Network Router')}</Title>
      <Accordion title={t('Set router settings')} margin="0.5rem 0">
        {chainId && !stateRouterAddress[chainId] ? (
          <>
            <Notice margin="0 0 0.5rem">{t('You need to deploy the router contract for each network.')}</Notice>
            <DeployRouter onDeploymentCallback={onDeployRouter} serverAdminAddress={stateServerAdminAddress} />
          </>
        ) : (
          <span />
        )}

        {!displayRouterSettings ? (
          <OptionWrapper>
            <ButtonOutlined onClick={showRouterSettings}>{t('I have already deployed the network router')}</ButtonOutlined>
          </OptionWrapper>
        ) : (
          <OptionWrapper>
            <Notice warning margin="0.4rem 0">
              {t('After router deployment, fill these settings.')}
            </Notice>
            <OptionLabel>
              <Select onChange={event => setRouterData(event.target.value)}>
                <SelectOption value="-">{t('Select a network router')}</SelectOption>
                {Object.keys(appSettings.multiswapConfigs).map((routerKey) => {
                  const routerInfo = appSettings.multiswapConfigs[routerKey]

                  return (
                    <SelectOption key={routerKey} value={routerKey}>
                      Chain {routerInfo.chainId} ({routerInfo.address})
                    </SelectOption>
                  )
                })}
              </Select>
            </OptionLabel>
            <OptionLabel displayChainsLink>
              {t('Router ChainID')}
              <Input
                type="number"
                min="1"
                step="1"
                placeholder=""
                value={routerChainId || chainId}
                onChange={event => setRouterChainId(event.target.value)}
              />
              {t('Router address')}
              <Input
                type="text"
                placeholder="0x..."
                value={routerAddress}
                onChange={event => setRouterAddress(event.target.value)}
              />
              {t('Required amount of block confirmations')}
              <Input
                type="text"
                value={routerConfirmCount}
                onChange={event => setRouterConfirmCount(event.target.value)}
              />
            </OptionLabel>
            {onConfigNetwork ? (
              <ButtonPrimary onClick={setChainConfig} disabled={!canSaveChainConfig}>
                {t('Set chain config')}
              </ButtonPrimary>
            ) : (
              <SwitchToChainButton chainId={stateMainConfigChainId} />
            )}
          </OptionWrapper>
        )}
      </Accordion>

      <TokenSettings
        appSettings={appSettings}
        multiswapConfig={multiswapConfig}
        routerAddress={routerAddress}
        multiswapConfigSigner={multiswapConfigSigner}
        onConfigNetwork={onConfigNetwork}
        SwitchToConfigButton={<SwitchToChainButton chainId={stateMainConfigChainId} />}
        updateUnderlyingToken={updateUnderlyingToken}
        onDeployCrosschainTokenCallback={onDeployCrosschainToken}
      />
    </>
  )
}
