import bsc, {BNB_MAIN_CHAINID} from './bsc'
import mochain, {MOCHAIN_MAIN_CHAINID} from './mochain'

import {VERSION, USE_VERSION, env} from '../constant'
interface ConFig {
  [key: string]: any
}
export const chainInfo:ConFig = {
  ...bsc,
  ...mochain,
  
}

const useChain:any = {
  [VERSION.V1]: [
    BNB_MAIN_CHAINID,
    MOCHAIN_MAIN_CHAINID,
  ],
  ALL_MAIN: [
    BNB_MAIN_CHAINID,
    MOCHAIN_MAIN_CHAINID,
  ]
}

const envType:any = env

const defChains = envType === 'dev' ? useChain['ALL_MAIN'] : useChain[USE_VERSION]

const enabledChains = (window as any)?.SO_CROSSCHAIN?.NETWORKS ? (window as any).SO_CROSSCHAIN.NETWORKS : defChains;
export const spportChainArr = enabledChains
