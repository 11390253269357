export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const baseURL = 'https://api.multiswap.org'

export const chainToChainUrl = baseURL + '/v2/allserverinfo'
export const toChainUrl = baseURL + '/v2/tokenlist'
export const dislineUrl = baseURL + '/v2/disline'
export const swapinStatusUrl = baseURL + '/v2/getHashStatus'
export const swapoutStatusUrl = baseURL + '/v2/getWithdrawHashStatus'
export const chainInfoUrl = baseURL + '/data/bridgeChainInfo'
export const routerInfoUrl = baseURL + '/v3/serverinfo'

export const recordsTxnsUrl = baseURL + '/v3/records'

export const routerVersion = 'STABLEV3'

export const timeout = 1000 * 60 * 30

export const LOCAL_DATA_LABEL = 'MULTISWAP-BRIDGE-SDK-V1-'

export const specSymbol = ['BTC', 'TRX']

export enum ChainId {
  MAINNET = 1,
  BNBTEST = 97,
}

export enum Status {
  Success = 'Success',
  Pending = 'Pending',
  Error = 'Error',
  Failure = 'Failure',
  Confirming = 'Confirming',
  Minting = 'Minting',
  Timeout = 'Timeout',
}

export const networks:any = {
  [ChainId.MAINNET]: 'https://ethmainnet.anyswap.exchange',
  [ChainId.BNBTEST]: 'https://bsc-dataseed1.ninicoin.io/',
}
