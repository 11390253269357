import React, { Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { BRIDGE_KEY } from '../../config/constant'
import CrossChainPanel from '../../components/CrossChainPanelV2'
import AppBody from '../AppBody'

export default function CrossChainBox() {
  return (
    <>
      <AppBody>
        <Suspense fallback={null}>
          <Switch>
            <Route
              exact
              strict
              path="/swap"
              component={() => <CrossChainPanel bridgeKey={BRIDGE_KEY.mergeTokenList} />}
            />
            <Redirect to="/swap" />
          </Switch>
        </Suspense>
      </AppBody>
    </>
  )
}
